.menu {
  z-index: 5;
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.cell {
  padding: 8px 20px 8px 19px;
  cursor: pointer;
  font-size: 14px;
}
.cell:hover {
  background: rgba(250, 140, 22, .35);
}
.icon {
  padding-right: 10px;
}