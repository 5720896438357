.graph-edit {
  .ant-drawer-body {
    padding: 12px;
  }
  .ant-form-item-control-wrapper {
    text-align: right;
  }
  .ant-form-item {
    margin-bottom: 12px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .item-cell-dark {
    background: #fafafa;
    border-radius: 4px;
    margin-bottom: 12px;
    overflow: hidden;
    padding: 0 12px;
    .ant-form-item {
      margin-bottom: 4px;
    }
    .dynamic-delete-button {
      position: absolute;
      top: 3px;
      right: 4px;
    }
  }
  .item-cell {
    padding: 0 12px;
  }
  .item-cell.link-edit {
    .ant-form-item-control-wrapper {
      text-align: left;
    }
  }
  .tunnel-sources-input {
    display: none;
  }
  .tunnel-sources-string {
    display: block;
    height: 40px;
    width: 100%;
  }
  .tunnel-sources:hover {
    .tunnel-sources-input {
      display: block;
    }
    .tunnel-sources-string {
      display: none;
    }
  }
}