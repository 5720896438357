.system .box {
  position: relative;
  float: left;
  background: #fff;
  width: 100%;
  min-width: 600px;
  max-width: 750px;
  margin-right: 40px;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
}
.system .box.max {
  max-width: 100%;
  min-width: 600px;
}
.system .box.login-box {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.system .main {
  position: relative;
  left: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.system .title {
  line-height: 40px;
  padding-left: 20px;
  font-size: 16px;
  background: #e8e8e8;
}
.system .line-right {
  width: 100%;
  text-align: right;
  overflow: hidden;
  padding-right: 10px;
  margin-bottom: 10px;
}
.system .line-left {
  width: 100%;
  text-align: left;
  overflow: hidden;
  padding-right: 10px;
  margin-bottom: 10px;
}
.system .backEdges {
  cursor: pointer;
  padding-left: 10px;
  float: left;
}
.system .backEdges:hover {
  color: rgb(231,148,17);
}
.system .button {
  color: #bbb;
  cursor: pointer;
}
.system .button:hover {
  color: rgb(231,148,17);
}
.system .skip-warnings {
  line-height: 40px;
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  left: 30px;
  border-radius: 3px;
  right: 30px;
  /* width: 100%; */
  top: 100px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  text-align: center;
  z-index: 3;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}
.systemLogs .ant-tooltip {
  max-width: 420px !important;
}