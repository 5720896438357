.graph-edit .ant-drawer-body {
  padding: 12px;
}
.graph-edit .ant-form-item-control-wrapper {
  text-align: right;
}
.graph-edit .ant-form-item {
  margin-bottom: 12px;
}
.graph-edit .item-cell-dark {
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  padding: 0 12px;
}
.graph-edit .item-cell-dark .ant-form-item {
  margin-bottom: 4px;
}
.graph-edit .item-cell-dark .dynamic-delete-button {
  position: absolute;
  top: 3px;
  right: 4px;
}
.graph-edit .item-cell {
  padding: 0 12px;
}
.graph-edit .item-cell.link-edit .ant-form-item-control-wrapper {
  text-align: left;
}
.graph-edit .tunnel-sources-input {
  display: none;
}
.graph-edit .tunnel-sources-string {
  display: block;
  height: 40px;
  width: 100%;
}
.graph-edit .tunnel-sources:hover .tunnel-sources-input {
  display: block;
}
.graph-edit .tunnel-sources:hover .tunnel-sources-string {
  display: none;
}
