.login {
  position: absolute;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  top: 20%;
}
.login .box {
  width: 100%;
  margin: 0;
}
